<template>
  <div class="search">
    <Card>
        <Row>
          <Form ref="searchForm" :model="searchForm" inline class="search-form">
            <Form-item class="br">
                <Button @click="handleParamInit" type="primary" icon="ios-search">参数初始化</Button>
                <Button @click="handleLuceneInit" type="warning" icon="ios-search">Lucene初始化</Button>
            </Form-item>
          </Form>
        </Row>
    </Card>
  </div>
</template>
<script>
    export default {
        name: "single-window",
        components: {},        
        data() {},
        methods: {},
        mounted(){},
    }
</script>
